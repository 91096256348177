<template>
  <div class="sof-box-owner">
    <div class="sof-box-owner-text">
      CrimeNetwork
    </div>
    <div class="sof-box-owner-tag">
      <GlTag
        :score="100"
        :show-score="false"
        tag="Darknet Marketplace"
      />
    </div>
  </div>
</template>

<script>
//Components
import GlTag from '@/components/gl-tag'

export default {
  components: {
    GlTag,
  },

  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style>
.sof-box-owner {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sof-box-owner-text {
  color: var(--space-cadet);
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 12px;
}
.sof-box-owner-tag .tag {
  max-width: 100%;
  font-size: 12px !important;
  padding: 4px 8px !important;
}
</style>
